import React from 'react';
import {
    SkeletonListingCardDatesStyled,
    SkeletonListingCardImageStyled,
    SkeletonListingCardLocationStyled,
    SkeletonListingCardPetIconStyled,
    SkeletonListingCardPetRowStyled,
    SkeletonListingCardTitleStyled,
} from './ListingCard.style';

const SkeletonListingCard = () => (
    <>
        <SkeletonListingCardImageStyled />
        <SkeletonListingCardTitleStyled />
        <SkeletonListingCardDatesStyled />
        <SkeletonListingCardLocationStyled />
        <SkeletonListingCardPetRowStyled>
            <SkeletonListingCardPetIconStyled />
            <SkeletonListingCardPetIconStyled />
        </SkeletonListingCardPetRowStyled>
    </>
);

export default SkeletonListingCard;
